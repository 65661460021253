import {
  Box,
  Button,
  Divider,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import DrawIcon from "@mui/icons-material/Draw";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import PaletteIcon from "@mui/icons-material/Palette";
import ChildCareIcon from "@mui/icons-material/ChildCare";
import FilePresentIcon from "@mui/icons-material/FilePresent";

export const Projects = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  return (
    <>
      <Stack>
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "2rem",
            width: "100%",
            padding: "2rem",
          }}
        >
          <Typography variant={"h2"} color={theme.palette.primary.main}>
            Our projects
          </Typography>
        </Box>
        <Divider variant="middle" />
        <Box>
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: "2rem",
              width: "100%",
              padding: "2rem",
            }}
          >
            <Typography variant={"h4"} color={theme.palette.primary.main}>
              Village Show
            </Typography>
          </Box>
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "2rem",
              width: "100%",
              padding: "2rem",
            }}
          >
            <Box>
              <Typography>
                Every year at the Radcliffe on Trent Village Show, we run the
                knitting/crochet/sewing categories and we have a stall where we
                promote the group. You can come meet us and take a look at the
                charities we support, or just get some inspiration, or event
                discuss projects and get advice.
              </Typography>
            </Box>
            <Typography>
              <strong>Knitting Categories for 2025 </strong>
            </Typography>
            <Box>
              <Box
                display={"flex"}
                flexDirection={"row"}
                gap={"1rem"}
                alignItems={"center"}
              >
                <MenuBookIcon />
                <Typography>
                  Technical knitting: an item made using double knitting
                </Typography>
              </Box>
              <Box
                display={"flex"}
                flexDirection={"row"}
                gap={"1rem"}
                alignItems={"center"}
              >
                <PaletteIcon />
                <Typography>
                  Creative 1: A set of matching hat and scarf/shawl
                </Typography>
              </Box>
              <Box
                display={"flex"}
                flexDirection={"row"}
                gap={"1rem"}
                alignItems={"center"}
              >
                <PaletteIcon />
                <Typography>
                  Creative 2: An item knitted in the round (no seam - for
                  example: hat, sock, infinity scarf, mittens etc)
                </Typography>
              </Box>
              <Box
                display={"flex"}
                flexDirection={"row"}
                gap={"1rem"}
                alignItems={"center"}
              >
                <PaletteIcon />
                <Typography>Creative 3: A colourful knitted cushion</Typography>
              </Box>
              <Box
                display={"flex"}
                flexDirection={"row"}
                gap={"1rem"}
                alignItems={"center"}
              >
                <FilePresentIcon />
                <Typography>
                  'Beginner' knitter: An item of your choice - bring the pattern
                  with the item, pattern must be labelled easy/beginner level
                </Typography>
              </Box>
              <Box
                display={"flex"}
                flexDirection={"row"}
                gap={"1rem"}
                alignItems={"center"}
              >
                <ChildCareIcon />
                <Typography>Children 1: A scarf</Typography>
              </Box>
              <Box
                display={"flex"}
                flexDirection={"row"}
                gap={"1rem"}
                alignItems={"center"}
              >
                <ChildCareIcon />
                <Typography>Children 2: A decoration</Typography>
              </Box>
            </Box>
            <Typography>
              <strong>Crochet Categories for 2025 </strong>
            </Typography>
            <Box>
              <Box
                display={"flex"}
                flexDirection={"row"}
                gap={"1rem"}
                alignItems={"center"}
              >
                <MenuBookIcon />
                <Typography>
                  Technical crochet: an item showing a crochet lace technique
                </Typography>
              </Box>
              <Box
                display={"flex"}
                flexDirection={"row"}
                gap={"1rem"}
                alignItems={"center"}
              >
                <PaletteIcon />
                <Typography>
                  Creative 1: A blanket (all styles, doesn't have to be granny
                  squares)
                </Typography>
              </Box>
              <Box
                display={"flex"}
                flexDirection={"row"}
                gap={"1rem"}
                alignItems={"center"}
              >
                <PaletteIcon />
                <Typography>Creative 2: A colourful crochet cushion</Typography>
              </Box>
              <Box
                display={"flex"}
                flexDirection={"row"}
                gap={"1rem"}
                alignItems={"center"}
              >
                <PaletteIcon />
                <Typography>
                  Creative 3: Toys and Amigurumi - Theme: Animals
                </Typography>
              </Box>
              <Box
                display={"flex"}
                flexDirection={"row"}
                gap={"1rem"}
                alignItems={"center"}
              >
                <FilePresentIcon />
                <Typography>
                  'Beginner' crocheter: An item of your choice - bring the
                  pattern with the item, pattern must be labelled easy/beginner
                  level
                </Typography>
              </Box>
              <Box
                display={"flex"}
                flexDirection={"row"}
                gap={"1rem"}
                alignItems={"center"}
              >
                <ChildCareIcon />
                <Typography>Children 1: A toy or amigurumi</Typography>
              </Box>
              <Box
                display={"flex"}
                flexDirection={"row"}
                gap={"1rem"}
                alignItems={"center"}
              >
                <ChildCareIcon />
                <Typography>Children 2: A decoration</Typography>
              </Box>
            </Box>

            <Typography>
              <strong>Adults' craft 2025</strong>
            </Typography>
            <Box>
              <Typography>Quilting: A quilt - any style</Typography>
              <Typography>Felting: A felt item - Theme: Animals</Typography>
              <Typography>Soft toys - Any fabrics/materials</Typography>
              <Typography>Handmade jewelry - Soldering</Typography>
              <Typography>Handmade jewelry - Beads</Typography>
            </Box>
          </Box>
        </Box>
        <Divider variant="middle" />
        <Box>
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: "2rem",
              width: "100%",
              padding: "2rem",
            }}
          >
            <Typography variant={"h4"} color={theme.palette.primary.main}>
              Knitting for charity
            </Typography>
          </Box>
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "3rem",
              width: "100%",
              padding: "2rem",
            }}
          >
            <Box>
              <Typography>
                It can be tough sometimes to bring an ongoing project to the
                knitting group, or you might be in a lull and have nothing going
                on at that moment. Or you might just have made too many items
                for yourself and everyone you know!
              </Typography>
              <Typography>
                Whatever the reason, if you're looking for small, fun and useful
                projects, we always have some charity projects ongoing in the
                background that you can pick up anytime!
              </Typography>
            </Box>
            <Box display={"flex"} flexDirection={"column"} gap={"1rem"}>
              <Typography>
                <strong>Premature babies</strong>: Whether you fancy making
                hats, jackets or simply small blankets, we have various patterns
                that help us knit items that will fit premature babies. We
                regularly collect items through the knitting sessions and they
                are donated to the local hospital.
              </Typography>
              <Box display={"flex"} gap={"1rem"}>
                <Button
                  variant="contained"
                  onClick={() =>
                    window.open(
                      "https://projectlinusuk.org.uk/premature-baby-hats/",
                      "_blank"
                    )
                  }
                >
                  Visit basic hat pattern
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() =>
                    window.open(
                      "https://mariannaslazydaisydays.blogspot.com/2014/03/al-most-as-soon-as-i-posted.html",
                      "_blank"
                    )
                  }
                >
                  More hat patterns
                </Button>
              </Box>
            </Box>
            <Box display={"flex"} flexDirection={"column"} gap={"1rem"}>
              <Typography>
                <strong>Care blankets</strong>: Destined to go to retirement
                homes and care homes, you can follow these patterns and make
                lovely cosy blankets for older folks. We often do this in tandem
                with the 'Knit for charity' group operates and advertises at the
                local library.
              </Typography>
              <Box>
                <Button
                  variant="contained"
                  onClick={() =>
                    window.open(
                      "https://www.inspireculture.org.uk/arts-culture/community/knit-crochet-craft-in-libraries/care-squares/#:~:text=A%2090%20cm%20(36%22),so%20blankets%20can%20be%20washed.",
                      "_blank"
                    )
                  }
                >
                  Visit care blanket pattern
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
        <Divider variant="middle" />
        <Box>
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: "2rem",
              width: "100%",
              padding: "2rem",
            }}
          >
            <Typography variant={"h4"} color={theme.palette.primary.main}>
              Wool exchange
            </Typography>
          </Box>
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "3rem",
              width: "100%",
              padding: "2rem",
            }}
          >
            <Box>
              <Typography>
                We hate when yarn goes to waste or stays unused for years and
                years in someone's drawer. So twice a year, we invite our
                members and anyone in the community to donate their unused wool
                to the group. We then proceed to make it available for purchase
                at a very low price of £1/yarn ball, as a symbolic gesture to
                support the group.
              </Typography>
            </Box>
            <Box display={"flex"} flexDirection={"column"} gap={"1rem"}>
              <Typography>
                <strong>Donations</strong>: We advertise the dates for drop off
                a little while in advance. It's generally open for a whole week
                and the drop off point will be at the Grange during opening
                hours. We accept yarn but also fabric, pattern/skills books and
                magazines.
              </Typography>
            </Box>
            <Box display={"flex"} flexDirection={"column"} gap={"1rem"}>
              <Typography>
                <strong>Wool exchange session</strong>: After a week of
                collecting donations, we have a selling session where everyone
                is welcome to come by and buy. We charge a symbolic price of
                £1/item which helps towards the cost of the group. We advertise
                the dates of these sessions a little while in advance, and it
                will be posted on facebook as well. We haven't quite worked out
                when the next one will be yet.
              </Typography>
            </Box>
          </Box>
        </Box>
      </Stack>
    </>
  );
};
