import "./App.css";
import ThemeCustomization from "./themes";
import Routes from "./routes";
import { Header } from "./components/header";
import FooterFull from "./components/footer/FooterFull";
import ScrollToTop from "./components/other/ScrollToTop";

function App() {
  return (
    <ThemeCustomization>
      <ScrollToTop />
      <Header />
      <Routes />
      <FooterFull />
    </ThemeCustomization>
  );
}

export default App;
