import { Box, Button, Typography } from "@mui/material";
import { useWindowDimensions } from "../../utils/mediaQuery";

const coloursArray = {
  grey: "141,141,141",
  primary: "121,88,163",
  secondary: "255, 115,0",
};
export const HomeCard = ({ imageName, text1, text2, colour, button }) => {
  const { width } = useWindowDimensions();
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "left",
        width: width > 768 ? "400px" : "100%",
        gap: "1rem",
        padding: "1rem",
        backgroundColor: "#ffffff",
        borderRadius: "8px",
        boxShadow: `0 0 6px 6px rgb(${coloursArray[colour]},0.2)`,
      }}
    >
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "left",
          backgroundImage: `url(/home/${imageName}.jpg)`,
          height: "200px",
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          borderRadius: "8px",
        }}
      ></Box>
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "start",
          alignItems: "left",
          flexGrow: 1,
          gap: "1rem",
          width: "100%",
          padding: "1rem",
        }}
      >
        <Typography style={{ fontWeight: "bold" }}>{text1}</Typography>
        {text2 && <Typography>{text2}</Typography>}
      </Box>
      {button && (
        <Button variant="contained" color={colour} onClick={button.onClick}>
          {button.label}
        </Button>
      )}
    </Box>
  );
};
