import { Box, Divider, Stack, Typography, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { LocationCard } from "../components/cards/locationCard";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { meetings2024 } from "../assets/data/meetings2024";

const dayNames = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export const Schedule = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  return (
    <Stack>
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "2rem",
          width: "100%",
          padding: "2rem",
        }}
      >
        <Typography variant={"h2"} color={theme.palette.primary.main}>
          Schedule
        </Typography>
      </Box>
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "2rem",
          width: "100%",
          padding: "2rem",
        }}
      >
        <Box>
          <Typography>
            Come join us for one of our knitting and crafts sessions.
          </Typography>
          <Typography>
            We meet regularly on Wednesday afternoons and Sundays afternoons.
            We're also thinking about introducing an evening session, but that's
            not been quite worked out yet.
          </Typography>
        </Box>
        <Typography>
          <strong>Wednesday</strong>: During term times, we meet at the Grange
          Hall in the center of Radcliffe on trent. We rent a room so there's
          plenty of space for our projects and to discuss anything and
          everything. During school holidays, we meet in Costa in the centre of
          the village where we have a catch up and enjoy a beverage with some
          light crafts for the ones that fancy it.
        </Typography>
        <Typography>
          <strong>Sunday</strong>: Once a month at present, generally the 1st
          Sunday of the month, we meet at Costa in the centre of the village and
          we socialise while exploring further crafts including sewing and
          quilting.
        </Typography>
        <Typography>
          <strong>One-off workshops</strong>: A few times a year, these would be
          tailored to what our members would like to learn and have asked for,
          or it might link to a village show category, to support everyone
          develop their skills and be able to enter items in the show. We
          haven't quite worked out all the details yet so there's no dates
          and/or themes set yet.
        </Typography>
        <Divider variant="middle" />
        <Typography variant={"h4"} color={theme.palette.primary.main}>
          Upcoming events
        </Typography>
        {meetings2024
          .filter((i) => i.date > new Date())
          .slice(0, 2)
          .map((i) => (
            <Box
              key={`${i.date}`}
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "start",
                alignItems: "center",
                gap: "1rem",
                width: "100%",
              }}
            >
              <CalendarTodayIcon />
              <Typography>{`${dayNames[new Date(i.date).getDay()]} ${new Date(
                i.date
              ).getDate()} ${new Date(i.date).toLocaleString("default", {
                month: "long",
              })} - ${new Date(i.date).getHours()}:${new Date(
                i.date
              ).getMinutes()} - ${i.locationName}`}</Typography>
            </Box>
          ))}
        <Divider variant="middle" />
        <Typography variant={"h4"} color={theme.palette.primary.main}>
          Locations
        </Typography>
        <Box
          display={"flex"}
          flexDirection={"row"}
          flexWrap={"wrap"}
          gap={"2rem"}
        >
          <LocationCard
            imageName={"grange-building"}
            locationName={"The Grange"}
            address={
              "Dowson room, Vicarage Ln, Radcliffe on Trent, Nottingham NG12 2FB"
            }
            day={"Wednesday"}
            timeslot={"1.30pm - 3.00pm"}
            mapLink={
              "https://www.google.com/maps/place/The+Grange+Hall/@52.9462607,-1.039641,15z/data=!4m6!3m5!1s0x4879c3c6769e5de5:0x1b9461df3071fcb7!8m2!3d52.9462607!4d-1.039641!16s%2Fg%2F12lsz02qr?entry=ttu"
            }
          />
          <LocationCard
            imageName={"costa-coffee"}
            locationName={"Costa"}
            address={"3 Main Rd, Radcliffe on Trent, NG12 2FD"}
            day={"Sunday / Holiday time"}
            timeslot={"1.30pm - 3.00pm"}
            mapLink={
              "https://www.google.com/maps/place/Costa+Coffee/@52.9469112,-1.0410097,17z/data=!3m1!4b1!4m6!3m5!1s0x4879c5d05229ff53:0x8fb815982aa29f28!8m2!3d52.946908!4d-1.0384348!16s%2Fg%2F11cmrz_v2n?entry=ttu"
            }
          />
        </Box>
      </Box>
    </Stack>
  );
};
